<template>
  <v-container>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
      height="290"
      style="background: #1E1E1E!important;"
    >
      <v-card
        dark
        color="black"
        style="background: #1E1E1E!important;"
      >
        <v-card-title class="display-1 mb-2">
          <div v-if="extend && success" class="notify-title">Your free trial has been extended! You can enjoy extra 7 days of unlimited streaming</div>
        </v-card-title>
        <div class="notify-box">
          <div v-if="extend && success">
            To get an instruction on how to get started, check our tutorial.
            <a href="https://intercom.help/onechannelone/en/collections/2733439-how-to" target="_blank">Go to tutorial</a>
          </div>
          <div v-else>
            {{ content }}
          </div>
        </div>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="purple"
            @click="okHandleEvent"
          >
            {{ okText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <over-lay-loading ref="overlayloading" />
  </v-container>
</template>

<script>
import { userTrialExtend } from '@/api/user'
// import OverDialog from './components/overDialog'
import { isAuth } from '@/util/auth'
import OverLayLoading from './components/overLayLoading'
import { getQueryVariable } from '@/util/index'

export default {
  name: 'TrialExtend',
  components: {
    OverLayLoading
  },
  data() {
    return {
      dialog: false,
      content: 'Give us a few seconds to redirect you to the login page, where you can extend your trial.',
      second: 5,
      interval: null,
      success: false
    }
  },
  computed: {
    okText() {
      return isAuth() ? 'OK' : 'OK (' + this.second + 's)'
    },
    extend() {
      return isAuth()
    }
  },
  created() {
    if (isAuth()) {
      this.$nextTick(() => {
        this.postData()
      })
    } else {
      this.$nextTick(() => {
        this.dialog = true
        this.interval = setInterval(() => {
          if (this.second === 0) {
            clearInterval(this.interval)
            var utm = { 'utm_source': getQueryVariable('utm_source'), 'utm_medium': getQueryVariable('utm_medium'), 'utm_campaign': getQueryVariable('utm_campaign') }
            this.$router.push({ name: 'login', query: Object.assign(utm, { 'redirect': '/auth/trialextend' }) })
            return
          }
          this.second--
        }, 1000)
      })
    }
  },
  methods: {
    postData() {
      this.$refs.overlayloading.controlShow(true)
      userTrialExtend().then(res => {
        if (res.code === 0) {
          this.success = true
        } else {
          this.content = res.message
        }
        this.$refs.overlayloading.controlShow(false)
        this.dialog = true
      }).finally(() => {
        this.$refs.overlayloading.controlShow(false)
      })
    },
    okHandleEvent() {
      if (isAuth()) {
        this.$router.push({ name: 'mychannel' })
        return
      }
      clearInterval(this.interval)
      this.$router.push({ name: 'login', query: { 'redirect': '/auth/trialextend' }})
    }
  }
}
</script>

<style lang="scss" scoped>
.notify-box{
    margin: 20px;
}
</style>
